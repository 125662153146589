<template>
  <b-card>

    <!-- search input -->
    <b-row class="mb-1">
      <b-col lg="4">
        <b-form-input
            v-on:keyup="onSearch"
            v-model.lazy="searchTerm"
            placeholder="Search..."
            type="text"
            class="d-inline-block mr-sm-1 mb-1 mb-sm-0"
        />
      </b-col>
      <b-col lg="8" class="d-flex justify-content-end align-items-center">
        <template
            v-if="$permissionAbility(ATTENDANCE_POLICY_CREATE, permissions)"
        >
          <b-button
              class="flex-shrink-0"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              v-on:click="onAddShow(masterPolicy)"
          >
            Create Policy
          </b-button>
        </template>
      </b-col>
    </b-row>

    <!-- table -->
    <vue-good-table
      styleClass="vgt-table table-custom-style condensed striped"
      mode="remote"
      @on-page-change="onPageChange"
      @on-sort-change="onSortChange"
      @on-column-filter="onColumnFilter"
      @on-per-page-change="onPerPageChange"
      :totalRows="totalRecords"
      :isLoading.sync="isLoading"
      :rows="rows"
      :sort-options="{
        enabled: false,
        multipleColumns: true,
        initialSortBy: [{ field: 'id', type: 'desc' }],
      }"
      :columns="columns"
      :pagination-options="{
        enabled: true,
        perPage: pageLength,
      }"

    >
      <template slot="table-row" slot-scope="props">

        <span class="font-weight-bold" v-if="props.column.field === 'formatted_check_in'">
            <template v-if="props.row?.check_in">
              {{ formatFnTableCheckIn(props.row?.check_in) }}
            </template>
          </span>

        <span class="font-weight-bold" v-if="props.column.field === 'formatted_check_out'">
            <template v-if="props.row?.check_out">
              {{ formatFnTableCheckIn(props.row?.check_out) }}
            </template>
          </span>

        <span class="font-weight-bold" v-if="props.column.field === 'formatted_work_hours'">
            <template v-if="props.row?.work_hours">
              {{ formatFnWorkHour(props.row?.work_hours) }}
            </template>
          </span>

        <!-- Column: Action -->
        <span v-if="props.column.field === 'action'">

        <template v-if="$permissionAbility(ATTENDANCE_POLICY_EDIT, permissions)">
            <span @click="onShow(props.row)">
              <feather-icon
                  v-b-tooltip.hover
                  icon="Edit2Icon"
                  class="mr-50 custom-icon cursor-pointer"
                  title="Edit"
                  size="16"
              />

            </span>
          </template>

          <template
                v-if="$permissionAbility(ATTENDANCE_POLICY_DELETE, permissions)"
            >
              <span @click="onDelete(props.row)">
                <feather-icon
                    v-b-tooltip.hover
                    icon="TrashIcon"
                    class="mr-50 custom-icon cursor-pointer"
                    title="Delete"
                    size="16"
                />
              </span>
            </template>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template slot="pagination-bottom" slot-scope="props">
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap"> Showing 1 to </span>
            <b-form-select
                v-model="pageLength"
                :options="['10', '25', '50', '100', '500']"
                class="mx-1"
                @input="
                (value) => props.perPageChanged({ currentPerPage: value })
              "
            />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>

    <b-modal
        id="modal-attendance-policy-remote-form"
        centered
        :title="modelType == 'editModel' ? 'Edit' : 'Create'"
        hide-footer
        @hidden="hiddenModal"
        no-close-on-backdrop
    >
      <validation-observer ref="remoteAttendancePolicyValidation">
        <b-form v-on:submit.prevent="validationForm">
          <!-- name -->
          <b-form-group class="required-label" label-for="name">
            <validation-provider
                #default="{ errors }"
                name="name"
                vid="name"
                rules="required|max:255"
            >
              <b-form-input
                  id="name"
                  type="text"
                  v-model="name"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Policy"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- check in-->
          <b-form-group label="Check In*" label-for="check-in">
            <validation-provider
                #default="{ errors }"
                name="check in"
                vid="check_in"
                rules="required"
            >
              <b-form-timepicker
                  id="check-in"
                  v-model="check_in"
                  locale="en"
                  reset-button
                  :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <!-- check out-->
          <b-form-group label="Check Out*" label-for="check-out">
            <validation-provider
                #default="{ errors }"
                name="check out"
                vid="check_out"
                rules="required"
            >
              <b-form-timepicker
                  id="check-out"
                  v-model="check_out"
                  locale="en"
                  reset-button
                  :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- work hours -->
          <b-form-group label="Work Hours" label-for="work-hours">
            <validation-provider
                #default="{ errors }"
                name="work hours"
                vid="work_hours"
                rules="required"
            >
              <b-form-timepicker
                  id="work-hours"
                  v-model="workHours"
                  locale="en"
                  :hour12="false"
                  reset-button
                  :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- delay_buffer_minutes -->
          <b-form-group
              label="Delay Buffer (Min)"
              label-for="delay_buffer_minutes"
          >
            <validation-provider
                #default="{ errors }"
                name="delay_buffer_minutes"
                vid="delay_buffer_minutes"
                rules="integer"
            >
              <b-form-input
                  id="delay_buffer_minutes"
                  type="number"
                  v-model="delayBufferMinutes"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Delay Buffer Minutes"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>


          <!-- delay_buffer_minutes -->
          <b-form-group
              label="Extreme Delay Buffer (Min)"
              label-for="extreme_delay_buffer_minutes"
          >
            <validation-provider
                #default="{ errors }"
                name="extreme_delay_buffer_minutes"
                vid="extreme_delay_buffer_minutes"
                rules="integer"
            >
              <b-form-input
                  id="extreme_delay_buffer_minutes"
                  v-model="extremeDelayBufferMinutes"
                  type="number"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Extreme Delay Buffer Minutes"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- over_time_minutes -->
          <b-form-group label="Buffered Overtime (Min)" label-for="over_time_minutes">
            <validation-provider
                #default="{ errors }"
                name="over_time_minutes"
                vid="over_time_minutes"
                rules="integer"
            >
              <b-form-input
                  id="over_time_minutes"
                  type="number"
                  v-model="overTimeMinutes"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Over Time Minutes"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- minimum_over_time_minutes -->
          <b-form-group
              label="Minimum Over Time (Min)"
              label-for="minimum_over_time_minutes"
          >
            <validation-provider
                #default="{ errors }"
                name="minimum_over_time_minutes"
                vid="minimum_over_time_minutes"
                rules="integer"
            >
              <b-form-input
                  id="minimum_over_time_minutes"
                  type="number"
                  v-model="minimumOverTimeMinutes"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Minimum Over Time Minutes"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- loading button -->
          <template v-if="isDesignationFormSubmitLoading">
            <b-button class="float-right" variant="primary" disabled>
              <b-spinner small />
              Loading...
            </b-button>
          </template>

          <!-- submit button -->
          <template v-else>
            <b-button
              type="submit"
              class="float-right"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
            >
              Submit
            </b-button>
          </template>
        </b-form>
      </validation-observer>
    </b-modal>
  </b-card>
</template>

<script>
import {
  BCard,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BForm,
  BModal,
  BSpinner,
  BFormTimepicker,
  BFormDatepicker, VBTooltip, BRow, BCol,
} from 'bootstrap-vue'
import { VueGoodTable } from "vue-good-table";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, max, integer } from "@validations";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapGetters } from "vuex";
import {
  ATTENDANCE_POLICY_CREATE,
  ATTENDANCE_POLICY_EDIT,
  ATTENDANCE_POLICY_DELETE,
} from "@/helpers/permissionsConstant";

export default {
  name: "RemoteAttendancePolicyView",
  components: {
    BCol,
    BRow,
    BForm,
    BButton,
    BCard,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    ValidationProvider,
    ValidationObserver,
    BModal,
    BSpinner,
    BFormTimepicker,
    BFormDatepicker,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      ATTENDANCE_POLICY_CREATE,
      ATTENDANCE_POLICY_EDIT,
      ATTENDANCE_POLICY_DELETE,
      modelType: "",
      masterPolicy: [],
      remoteAttendancePolicyId: "",
      name: "",
      check_in: "",
      check_out: "",
      workHours: "",
      delayBufferMinutes: "",
      extremeDelayBufferMinutes: "",
      overTimeMinutes: "",
      minimumOverTimeMinutes: "",
      // effectiveDate: "",
      // selectEmployeeTypeId: "",
      // employeeTypesOption: [],
      pageLength: 10,
      columns: [
        {
          label: "Name",
          field: "name",
        },
        // {
        //   label: "Effective Date",
        //   field: "effective_date",
        // },
        {
          label: "Check In",
          field: "formatted_check_in",
          sortable: false,
          formatFn: this.formatFnTableCheckIn,
        },
        {
          label: "Check Out",
          field: "formatted_check_out",
          sortable: false,
          formatFn: this.formatFnTableCheckIn,
        },
        {
          label: "Delay Buffer (Min)",
          field: "delay_buffer_minutes",
          sortable: false,
        },{
          label: "Extreme Delay Buffer (Min)",
          field: "extreme_delay_buffer_minutes",
          sortable: false,
        },
        {
          label: "Over Time (Min)",
          field: "over_time_minutes",
          sortable: false,
        },
        {
          label: "Minimum Over Time (Min)",
          field: "minimum_over_time_minutes",
          sortable: false,
        },
        {
          label: "Work Hours",
          field: "formatted_work_hours",
          sortable: false,
          formatFn: this.formatFnTableCheckIn,
        },
        {
          label: "Action",
          field: "action",
          sortable: false,
        },
      ],
      rows: [],
      searchTerm: "",
      delayTimer: null,
      isLoading: false,
      isDesignationFormSubmitLoading: false,
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: [
          { field: "id", type: "desc" },
          { field: "name", type: "desc" },
          { field: "created_at", type: "desc" },
        ],
        page: 1,
        perPage: 10,
      },
    };
  },

  computed: {
    ...mapGetters({
      permissions: "userModule/getPermissions",
    }),
  },

  async created() {
    try {
      const masterSettings = await this.$api.get('/api/settings')
      this.masterPolicy = masterSettings?.data?.data
    } catch (error) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          icon: "BellIcon",
          variant: "danger",
          text: error?.response?.data?.message,
        },
      });
    }
  },

  methods: {
    formatFnTableCheckIn(value) {
      if (value) {
        return this.$moment(value, "HH:mm:ss").format("LT");
      }
    },
    formatFnWorkHour(value) {
      if (value) {
        return this.$moment(value, "HH:mm").format("HH:mm");
      }
    },
    onContextStartDate(ctx) {
      this.effectiveDate = ctx.selectedYMD;
    },
    showModal() {
      this.$bvModal.show("modal-attendance-policy-remote-form");
    },
    hiddenModal() {
      this.modelType = "";
      this.$bvModal.hide("modal-attendance-policy-remote-form");
      this.resetModal();
    },
    resetModal() {
      this.name = "";
      this.remoteAttendancePolicyId = "";
      this.check_in = "";
      this.check_out = "";
      this.delayBufferMinutes = "";
      this.extremeDelayBufferMinutes = "";
      this.overTimeMinutes = "";
      this.minimumOverTimeMinutes = "";
      this.workHours = "";
    },
    async onShow(value) {
      this.modelType = "editModel";
      this.name = value?.name;
      this.remoteAttendancePolicyId = value?.id;
      this.check_in = value?.check_in;
      this.check_out = value?.check_out;
      this.delayBufferMinutes = value?.delay_buffer_minutes;
      this.extremeDelayBufferMinutes = value?.extreme_delay_buffer_minutes;
      this.overTimeMinutes = value?.over_time_minutes;
      this.minimumOverTimeMinutes = value?.minimum_over_time_minutes;
      this.workHours = value?.work_hours;

      this.showModal()
    },
    async onAddShow(value) {
      console.log(value)
      this.name = value?.name
      this.remoteAttendancePolicyId = value?.id
      this.check_in = value?.check_in_out_time[0]?.value
      this.check_out = value?.check_in_out_time[1]?.value
      this.delayBufferMinutes = value?.delay_buffer_minutes
      this.extremeDelayBufferMinutes = value?.extreme_delay_buffer_minutes
      this.overTimeMinutes = value?.over_time_minutes
      this.minimumOverTimeMinutes = value?.minimum_over_time_minutes
      this.workHours = value?.work_hours

      this.showModal();
    },

    async onDelete(row) {
      const { id, name } = row
      console.log(row)
      this.$swal({
        title: "Warning!",
        text: `Are You Sure, You Want To Delete ${name}?`,
        icon: "warning",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: "Ok",
        showLoaderOnConfirm: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await this.$api.delete(`/api/attendances/policy/remote/${id}`);

            this.loadItems();

            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Success",
                icon: "BellIcon",
                variant: "success",
                text: "Successfully Deleted",
              },
            });
          } catch (error) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                icon: "BellIcon",
                variant: "danger",
                text: error?.response?.data?.message,
              },
            });
          }
        }
      });
    },

    onSearch() {
      if (this.delayTimer) {
        clearTimeout(this.delayTimer);
        this.delayTimer = null;
      }

      this.delayTimer = setTimeout(() => {
        this.loadItems();
      }, 1000);
    },

    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.loadItems();
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      this.loadItems();
    },

    onSortChange(params) {
      this.updateParams({
        sort: params,
      });
      this.loadItems();
    },

    onColumnFilter(params) {
      this.updateParams(params);
      this.loadItems();
    },

    async getRemoteAttendancePolicy(params) {
      return await this.$api.get("api/attendances/policy/remote", {
        params: {
          show: params.show,
          page: params.page,
          sort: params.sort,
          q: params.q,
        },
      });
    },
    // async getEmployeeTypes() {
    //   return await this.$api.get("api/employee-types/all");
    // },

    async loadItems() {
      try {
        const [remoteAttendancePolicy] = await Promise.all([
          this.getRemoteAttendancePolicy({
            show: this.serverParams.perPage,
            page: this.serverParams.page,
            sort: this.serverParams.sort,
            q: this.searchTerm,
          }),
        ]);

        const data = remoteAttendancePolicy?.data?.data;
        const meta = remoteAttendancePolicy?.data?.meta;

        this.totalRecords = meta?.pagination?.total;
        this.rows = data;
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Warning",
            icon: "BellIcon",
            variant: "warning",
            text: error?.response?.data?.message,
          },
        });
      }
    },
    validationForm: async function () {
      this.$refs.remoteAttendancePolicyValidation
          .validate()
          .then(async (success) => {
            if (success) {
              try {
                if (this.modelType == "editModel") {
                  this.isDesignationFormSubmitLoading = true;
                  await this.$api.put(
                      `/api/attendances/policy/remote/${this.remoteAttendancePolicyId}`,
                      {
                        name: this.name,
                        check_in: this.check_in,
                        check_out: this.check_out,
                        delay_buffer_minutes: this.delayBufferMinutes,
                        extreme_delay_buffer_minutes: this.extremeDelayBufferMinutes,
                        over_time_minutes: this.overTimeMinutes,
                        minimum_over_time_minutes: this.minimumOverTimeMinutes,
                        work_hours: this.workHours,
                      }
                  );
                  this.isDesignationFormSubmitLoading = false;
                  this.loadItems();
                  this.hiddenModal();
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: "Success",
                      icon: "BellIcon",
                      variant: "success",
                      text: "Successfully Updated",
                    },
                  });
                } else {
                  this.isDesignationFormSubmitLoading = true;
                  await this.$api.post("/api/attendances/policy/remote", {
                    name: this.name,
                    check_in: this.check_in,
                    check_out: this.check_out,
                    delay_buffer_minutes: this.delayBufferMinutes,
                    extreme_delay_buffer_minutes: this.extremeDelayBufferMinutes,
                    over_time_minutes: this.overTimeMinutes,
                    minimum_over_time_minutes: this.minimumOverTimeMinutes,
                    work_hours: this.workHours,
                  });
                  this.isDesignationFormSubmitLoading = false;
                  this.hiddenModal();

                  this.loadItems();

                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: "Success",
                      icon: "BellIcon",
                      variant: "success",
                      text: "Successfully Created",
                    },
                  });
                }
              } catch (error) {
                this.isDesignationFormSubmitLoading = false;
                if (error?.response?.data?.message) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: "Error",
                      icon: "BellIcon",
                      variant: "danger",
                      text: error?.response?.data?.message,
                    },
                  });
                }

                if (error?.response?.data?.errors) {
                  this.$refs.remoteAttendancePolicyValidation.setErrors(
                      error?.response?.data?.errors
                  );
                }
              }
            }
          });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
.table-custom-style {
  font-size: 14px !important;
  white-space: nowrap !important;
  min-height: 140px !important;
  tr,
  th,
  td {
    vertical-align: left !important;
    text-align: left !important;
  }
}

.actionBtn span{
  padding: 8px;
  margin: 1px;
  cursor: pointer;
}
</style>
